.Header {
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    padding-right: 20px;
}

.Cart {
    display: flex;
    align-items: center;
}

.Cart__Number {
    color: black;
}

.Avatar {
    margin: 0 20px;
}

.Chip {
    background: white !important;
}
